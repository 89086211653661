// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

// scss-docs-start gray-color-variables
$white: #ffffff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000000;

// scss-docs-start color-variables
$blue: #00aaff;
$navy-blue: #1c1463;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #d63384;
$red: #ed5b66;
$orange: #fd7e14;
$yellow: #f5b343;
$green: #20baa0;
$teal: #20c997;
$cyan: #2e91d2;

// scss-docs-start theme-color-variables
$primary: $blue;
$secondary: $navy-blue;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: #0a0722;
$dark-1: #130d42;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.
$enable-caret: true;
$enable-rounded: false;
$enable-shadows: false;
$enable-gradients: false;
$enable-transitions: true;
$enable-reduced-motion: true;
$enable-smooth-scroll: true;
$enable-grid-classes: true;
$enable-cssgrid: false;
$enable-button-pointers: true;
$enable-rfs: true;
$enable-validation-icons: true;
$enable-negative-margins: false;
$enable-deprecation-messages: true;
$enable-important-utilities: true;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// scss-docs-start font-variables
// stylelint-disable value-keyword-case
//$font-size-root: 14px;
$font-family-sans-serif: "Open Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
  "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$headings-font-family: "Raleway", sans-serif;
$headings-font-weight: 700;

$lead-font-size: 1.125rem /* 18/16 */;
$lead-font-weight: 400;

$font-size-base: 1rem;

$h1-font-size: $font-size-base * 2.5;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.75;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base;

$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size,
  7: 0.9rem,
  8: 0.8rem,
);

// Body
//
// Settings for the `<body>` element.

$body-color: $dark-1;

// Links
//
// Style anchor elements.

$link-color: $navy-blue;
$link-decoration: underline;

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

// scss-docs-start input-btn-variables
$input-btn-padding-y: 0.375rem;
$input-btn-padding-x: 0.75rem;
$input-btn-font-family: null;

$input-btn-padding-y-sm: 0.25rem;
$input-btn-padding-x-sm: 0.5rem;

$input-btn-padding-y-lg: 0.5rem;
$input-btn-padding-x-lg: 1rem;

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.
$input-border-color: $secondary;
$input-border-width: 0.125rem;
$form-floating-padding-x: 0;
$form-floating-label-transform: scale(0.8) translateY(-0.5rem) translateX(-0.15rem);
$form-check-input-checked-bg-color: $white;
$form-check-input-checked-border-color: rgba($black, 0.25);
$form-check-input-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$secondary}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/></svg>");
$form-check-radio-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$secondary}'/></svg>");

$form-select-padding-x: 0;
